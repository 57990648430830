.input {
    min-width: 50px;
    height: 50px;
    border-radius: 10px;
    font-size: 25px;
    font-weight: 500;
    background-color: transparent;
    border-color: #ffdac4;
    color: #ffdac4;
}
